import React from "react"
import { Link } from "gatsby"
import styles from "./index.module.css"

const Tag = ({ tag, size = 1 }) => (
  <Link to={`/tag/${tag}`} className={styles.link}>
    <span
      className={styles.root}
      style={{ fontSize: `${Math.min(1 + 0.4 * size, 8)}rem` }}
    >
      #{tag}
    </span>
  </Link>
)

export default Tag
