import React from "react"
import { StaticQuery, graphql } from "gatsby"
import countBy from "lodash/countBy"

import Bio from "../components/Bio"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Tag from "../components/Tag"

const Tags = ({ location }) => (
  <Layout location={location} title="Tagi">
    <SEO title="Tagi" keywords={[`tagi`, 'kategorie', 'mapa']} />
    <article className="page">
      <h1>Tagi</h1>
      <p>
        Co to by był za blog bez chmury tagów! W końcu wszyscy tak kochamy produkty w chmurze!
      </p>
      <StaticQuery
        query={tagQuery}
        render={data => {
          const tags = data.allMarkdownRemark.edges.flatMap(
            p => p.node.frontmatter.tags
          )

          const tagCounts = countBy(tags)

          return Object.keys(tagCounts)
            .sort((a, b) => tagCounts[b] - tagCounts[a])
            .map(tag => <Tag key={tag} tag={tag} size={tagCounts[tag]} />)
        }}
      />
    </article>
    <Bio />
  </Layout>
)

export default Tags

const tagQuery = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      edges {
        node {
          frontmatter {
            tags
          }
        }
      }
    }
  }
`
